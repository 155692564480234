import { css } from '@emotion/react';

export const backgroundCx = css`
  display: block;
  height: 100%;
  width: 100%;

  background: url('https://storage.googleapis.com/besthome/general/20220907010208_73389d8b193d28e6.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
