import { css } from '@emotion/react';

export const containerCx = css`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const boxCx = css`
  max-width: 355px;
`;
