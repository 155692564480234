import type { VFC, ReactNode } from 'react';
import Container from '@mui/material/Container';

import { containerCx } from './styles';

interface Props {
  children?: ReactNode;
}

/**
 * @function DesktopContainer
 * @params props
 */
const DesktopContainer: VFC<Props> = props => {
  const { children } = props;
  return (
    <Container css={containerCx} disableGutters maxWidth={false}>
      {children}
    </Container>
  );
};

export default DesktopContainer;
