import type { VFC, ReactNode } from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { containerCx, boxCx } from './styles';

interface Props {
  children?: ReactNode;
}

/**
 * @function DesktopPlatform
 * @params props
 */
const DesktopPlatform: VFC<Props> = props => {
  const { children } = props;
  return (
    <Container css={containerCx} disableGutters>
      <Box css={boxCx}>{children}</Box>
    </Container>
  );
};

export default DesktopPlatform;
