import type { VFC } from 'react';

import { backgroundCx } from './styles';

/**
 * @function Background
 * @params props
 */
const Background: VFC<unknown> = () => {
  return <div css={backgroundCx} />;
};

export default Background;
