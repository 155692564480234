import type { FC } from 'react';

import Grid from '@mui/material/Grid';

import { useNotifUpdater } from '@/context/notification';

import useRegistrationCheck from '../usecase/use-registration-check';
import useIsExistEmail from '../repository/is-exist-email/use-is-exist-email';
import useLogin from '../repository/login/use-login';
import useLoginEvent from '../usecase/use-login-event';

import DesktopContainer from '../presentations/DesktopContainer';
import DesktopPlatform from '../presentations/DesktopPlatform';
import Background from '../presentations/Background';
import Heading from '../presentations/Heading';

import PageMeta from '../components/PageMeta';
import LoginForm from '../components/LoginForm';

/**
 * @function Desktop
 */
const Desktop: FC<Record<string, unknown>> = () => {
  const notification = useNotifUpdater();

  /**
   * Login repository & usecase
   */
  const { onSuccess, onError } = useLoginEvent({
    pushNotification: notification.push,
  });
  const { loading, onLogin } = useLogin({
    onError: onError,
    onSuccess: onSuccess,
  });

  /**
   * Email verification repository & usecase
   */
  const { registered, onResetVerification, onVerificationSuccess, onVerificationError } = useRegistrationCheck({
    pushNotification: notification.push,
  });
  const { verifying, onVerification } = useIsExistEmail({
    onError: onVerificationError,
    onSuccess: onVerificationSuccess,
  });

  return (
    <DesktopContainer>
      <PageMeta />
      <Grid container>
        <Grid item xs={6}>
          <Background />
        </Grid>
        <Grid item xs={6}>
          <DesktopPlatform>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Heading />
              </Grid>
              <Grid item xs={12}>
                <LoginForm
                  loading={loading}
                  registered={registered}
                  verifying={verifying}
                  onLogin={onLogin}
                  onEmailVerification={onVerification}
                  onResetVerification={onResetVerification}
                />
              </Grid>
            </Grid>
          </DesktopPlatform>
        </Grid>
      </Grid>
    </DesktopContainer>
  );
};

export default Desktop;
